import React from 'react';
import {FIND_ALL_CIDADE as query} from '../../graphql/queries';
import {SelectBase} from '../../components/select/mui-base';

const getVariables = ({pageNumber, search = '', searchDTO = {}, pageSize = 10}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nome', sortDir: 'ASC'},
    searchDTO: {
      ...searchDTO,
      search
    }
  };
};

export const SelectCidades = ({searchDTO, ...props}) => {
  return (
    <SelectBase
      label="Cidade"
      responseObjectName="findAllCidade"
      getVariables={(props) => getVariables({searchDTO, ...props})}
      labelProp="label"
      query={query}
      {...props}
    />
  );
};
